import { useRef } from 'react'

import { MagnifyingGlass, X } from '@phosphor-icons/react'
import { InputText } from 'primereact/inputtext'

type Props = {
  handleSearchKeywordChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  handleSearchClear: () => void
  handleSearchFocus?: () => void
  searchKeyword: string
  placeholder: string
}

export default function SearchBar({
  handleSearchClear,
  handleSearchFocus,
  handleSearchKeywordChange,
  placeholder,
  searchKeyword,
}: Props) {
  const hasSearchKeyword = searchKeyword.length > 0
  const searchEl = useRef<HTMLInputElement>(null)

  return (
    <div className='relative flex h-9'>
      <span className='pointer-events-none absolute bottom-0 left-3 top-0 flex items-center text-gray-400'>
        <MagnifyingGlass size={20} />
      </span>

      <InputText
        ref={searchEl}
        autoFocus
        className='h-9 flex-1 px-10 text-sm shadow-none placeholder:text-sm'
        placeholder={placeholder}
        value={searchKeyword}
        onChange={handleSearchKeywordChange}
        onFocus={handleSearchFocus}
      />

      {hasSearchKeyword && (
        <button
          className='absolute bottom-0 right-3 top-0 flex items-center text-gray-400'
          onClick={() => {
            handleSearchClear()
            searchEl.current?.focus()
          }}
        >
          <X className='text-gray-600' size={20} />
        </button>
      )}
    </div>
  )
}
