import { useEffect, useRef, useState } from 'react'

import { useDebounce } from '@uidotdev/usehooks'
import { useRouter } from 'next/router'

import SearchBar from '@components/Header/SearchBar'
import SearchResults from '@components/Header/SearchResults'
import { useOnClickOutside } from '@hooks/use-on-click-outside'

export default function Search() {
  const router = useRouter()

  const [isSearchOpen, setIsSearchOpen] = useState(false)
  const [searchKeyword, setSearchKeyword] = useState('')

  const ref = useRef(null)
  const debouncedSearchKeyword = useDebounce(searchKeyword, 1000)

  const handleSearchKeywordChange = (e) => {
    setIsSearchOpen(true)
    setSearchKeyword(e.target.value)
  }

  const handleSearchClear = () => {
    setSearchKeyword('')
  }

  const handleSearchFocus = () => {
    if (searchKeyword) {
      setIsSearchOpen(true)
    }
  }

  useEffect(() => {
    const closeSearch = () => {
      setIsSearchOpen(false)
      handleSearchClear()
    }

    router.events.on('routeChangeStart', closeSearch)

    return () => router.events.off('routeChangeStart', closeSearch)
  }, [router])

  useOnClickOutside(ref, () => setIsSearchOpen(false))

  return (
    <>
      <div
        ref={ref}
        className='relative z-[120] mx-4 flex w-full max-w-[24rem] flex-1 flex-col max-md:hidden'
      >
        <SearchBar
          handleSearchClear={handleSearchClear}
          handleSearchFocus={handleSearchFocus}
          handleSearchKeywordChange={handleSearchKeywordChange}
          placeholder='search surveys...'
          searchKeyword={searchKeyword}
        />

        {isSearchOpen && <SearchResults searchKeyword={debouncedSearchKeyword} />}
      </div>

      {isSearchOpen && <div className='fixed inset-0 z-[110] bg-gray-600/40 backdrop-blur' />}
    </>
  )
}
