import { useEffect } from 'react'

import { mixpanel } from '@libs/mixpanel'
import { setUser } from '@sentry/nextjs'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { useRouter } from 'next/router'

import Header from '@components/Header'
import { AccessDenied } from '@components/Layout/AccessDenied'
import DefaultLayout from '@components/Layout/DefaultLayout'
import { OverlayLoader } from '@components/Loader'
import { usePermissions } from '@hooks/use-permissions'
import { useUser } from '@hooks/use-user'
import GetReportButton from '@views/project/project-aside/GetReportButton'
import { routePermissionsMapping } from 'src/client/data/permissions-mapping'

dayjs.extend(utc)

type Props = {
  children: React.ReactNode
}

function checkIsAccessForbidden({ cannot, pathname }) {
  const [action, subject] = routePermissionsMapping[pathname] || []

  if (!action || !subject) {
    return false
  }

  return cannot(action, subject)
}

export default function Layout({ children }: Props) {
  const { isLoading, logIn, user } = useUser()
  const { cannot } = usePermissions()
  const { pathname } = useRouter()

  useEffect(() => {
    if (user) {
      setUser({
        email: user.email ?? undefined,
        username: user.name ?? undefined,
        organisationId: user['qrowdsy/organisationId'] ?? undefined,
      })
      mixpanel.identify(user.sub)
      mixpanel.people.set({
        'Email': user.email,
        'Organisation Id': user['qrowdsy/organisationId'],
        'User name': user.name,
        'Last login (UTC)': dayjs().utc().format(),
      })
    } else {
      setUser(null)
      mixpanel.reset()
    }
  }, [user])

  if (isLoading) {
    return <OverlayLoader />
  }

  if (!user) {
    logIn()

    return null
  }

  const isAccessForbidden = checkIsAccessForbidden({ pathname, cannot })
  const isBuilderPage = pathname.startsWith('/survey/[surveyId]/builder')
  const isReportingPage = pathname.startsWith('/survey/[surveyId]/reporting')
  const isBulkFormPage = pathname.startsWith('/survey/[surveyId]/bulk-form')

  if (isAccessForbidden) {
    return <AccessDenied />
  }

  if (isBuilderPage || isReportingPage || isBulkFormPage) {
    return <>{children}</>
  }

  return (
    <>
      <Header />
      <DefaultLayout>{children}</DefaultLayout>
      <GetReportButton />
    </>
  )
}
