import { MagnifyingGlass, Spinner } from '@phosphor-icons/react'
import Link from 'next/link'
import { useRouter } from 'next/router'

import { useSurveys } from '@hooks/use-survey-provider-service-api'
import SurveyStatusBadge from '@views/surveys/SurveyStatusBadge'

type Props = {
  searchKeyword: string
}

const MAXIMUM_SEARCH_RESULTS = 5

export default function SearchResults({ searchKeyword }: Props) {
  const router = useRouter()

  const { isLoadingSurveys, surveys } = useSurveys({ searchParams: { name: searchKeyword } })

  if (isLoadingSurveys) {
    return <Spinner className='absolute bottom-0 right-8 top-0 m-auto animate-spin' />
  }

  if (surveys?.length === 0) {
    return (
      <div className='absolute top-12 z-10 w-full rounded-md border border-gray-200 bg-white p-3 text-sm text-black'>
        No results found
      </div>
    )
  }

  const resultsCount = surveys?.length

  const handleShowAllSurveysClick = (e) => {
    e.preventDefault()

    const searchResultsURL = `/projects?search=${searchKeyword}`
    router.replace(searchResultsURL)
  }

  return (
    <div className='absolute top-12 z-10 w-full rounded-md border border-gray-200 bg-white text-sm text-black'>
      {surveys?.map((survey, index) => {
        if (index > MAXIMUM_SEARCH_RESULTS - 1) {
          return null
        }

        return (
          <Link
            key={survey.id}
            className='flex p-3 hover:bg-gray-100'
            href={`/survey/${survey.id}`}
          >
            <div className='flex w-full justify-between'>
              <span>{survey.name}</span>
              <SurveyStatusBadge surveyStatus={survey.surveyStatus} />
            </div>
          </Link>
        )
      })}

      {resultsCount > MAXIMUM_SEARCH_RESULTS && (
        <div className='flex w-full cursor-pointer p-3 hover:bg-gray-100'>
          <div className='flex gap-x-2 font-bold' onClick={handleShowAllSurveysClick}>
            <MagnifyingGlass size={16} weight='bold' />
            <span>See all {resultsCount} results</span>
          </div>
        </div>
      )}
    </div>
  )
}
