import { useEffect } from 'react'

export function useOnClickOutside(
  ref: React.MutableRefObject<HTMLElement>,
  handler: (event: React.SyntheticEvent) => void,
) {
  useEffect(() => {
    const listener = (event) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return
      }
      handler(event)
    }
    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)

    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
  }, [ref, handler])
}
