import type { SurveyFormState } from '@views/survey-form/typings/SurveyForm'

import type { AppLayoutType } from '@typings/AppLayout'
import type { FraudCheckId, FraudDetectionStatus } from '@typings/Fraud'
import type { InterviewParticipantType } from '@typings/Interviews'
import type { CalendarParticipantType } from '@typings/Interviews'
import type { PaymentMethodType, PaymentStatus, PaymentStatusType } from '@typings/Payment'
import type {
  Currency,
  Language,
  ProjectType,
  ResponseFlag,
  SurveyPlatform,
  SurveyProvider,
  SurveyStatus,
} from '@typings/Survey'
import type { WorkflowFlag, WorkflowStateType } from '@typings/Workflows'

export const DATE_FORMAT = 'DD/MM/YYYY'

export const DATE_HOUR_FORMAT = 'DD/MM/YYYY (HH:mm)'

export const DEFAULT_REFERRER_PERCENTAGE = 0.2

export const DEFAULT_CURRENCY: Currency = 'GBP'

export const LANGUAGE_OPTIONS: Array<Language> = ['EN-GB', 'FR', 'DE', 'IT', 'PT', 'ES']

export const DEFAULT_SURVEY_LANGUAGE = 'EN-GB'

export const DEFAULT_SURVEY_PLATFORM: SurveyPlatform = 'qrowdsy'

export const MAX_AWS_FILE_SIZE_UPLOAD_IN_BYTES = 1024 * 1024 * 200 // 200 MB

export const QUALTRICS_RESPONSE_FEE_IN_DEFAULT_CURRENCY = 1

type ProjectTypeWithoutNotUsed = Exclude<ProjectType, 'NOT_USED'>

export const PROJECT_TYPE: Record<ProjectTypeWithoutNotUsed, ProjectTypeWithoutNotUsed> = {
  SURVEY: 'SURVEY',
  INTERVIEW: 'INTERVIEW',
}

export const SURVEY_PROVIDERS: Record<SurveyProvider, SurveyProvider> = {
  QUALTRICS: 'QUALTRICS',
  SURVEYJS: 'SURVEYJS',
}

export const WORKFLOW_STATE_TYPES: Record<WorkflowStateType, WorkflowStateType> = {
  ON_HOLD: 'ON_HOLD',
  NEW: 'NEW',
  IN_PROGRESS: 'IN_PROGRESS',
  ACCEPTED: 'ACCEPTED',
  NOT_ACCEPTED: 'NOT_ACCEPTED',
}

export const DISQUALIFIED_WORKFLOW_STATE = 'DISQUALIFIED'

export const WORKFLOW_STATE_FLAGS: Record<WorkflowFlag, WorkflowFlag> = {
  REDIRECTED_TO_EXTERNAL: 'REDIRECTED_TO_EXTERNAL',
  EMAIL_REJECTION: 'EMAIL_REJECTION',
  SCHEDULE_INTERVIEW: 'SCHEDULE_INTERVIEW',
  UPDATE_QUOTA: 'UPDATE_QUOTA',
}

export const R_FLAG: Record<ResponseFlag, ResponseFlag> = {
  REFERRAL_SENT: 'REFERRAL_SENT',
}

export const P_STATUS: Record<PaymentStatus, PaymentStatus> = {
  PAID: 'PAID',
  NOT_INITIATED: 'NOT_INITIATED',
  PAYMENT_PENDING: 'PAYMENT_PENDING',
  PAYMENT_FAILED: 'PAYMENT_FAILED',
  NOT_PAID: 'NOT_PAID',
}

export const P_STATUS_TYPE: Record<PaymentStatusType, PaymentStatusType> = {
  RESPONSE: 'RESPONSE',
  REFERRAL: 'REFERRAL',
}

export const S_STATUS: Record<SurveyStatus, SurveyStatus> = {
  ARCHIVED: 'ARCHIVED',
  CLOSED: 'CLOSED',
  IN_PREPARATION: 'IN_PREPARATION',
  IN_PROGRESS: 'IN_PROGRESS',
  PAUSED: 'PAUSED',
}

export const F_STATUS: Record<FraudDetectionStatus, FraudDetectionStatus> = {
  FAILED: 'FAILED',
  PASSED: 'PASSED',
  SKIPPED: 'SKIPPED',
}

export const ACTIVE_SURVEY_STATUSES = [
  S_STATUS.IN_PREPARATION,
  S_STATUS.IN_PROGRESS,
  S_STATUS.PAUSED,
]

export const NON_ARCHIVED_PROJECT_STATUSES = [
  S_STATUS.CLOSED,
  S_STATUS.IN_PREPARATION,
  S_STATUS.IN_PROGRESS,
  S_STATUS.PAUSED,
]

export const CONFIG_IDS = {
  HAS_REDIRECT: 'hasRedirect',
}

export const LOGO_MAX_SIZE = 2_000_000

export const FRAUD_CHECKS: Record<FraudCheckId, FraudCheckId> = {
  IP_CONNECTIONS: 'IP_CONNECTIONS',
  SPELLCHECK: 'SPELLCHECK',
  VPN: 'VPN',
  REPEATED_ANSWERS: 'REPEATED_ANSWERS',
  DEFAULT_RANK: 'DEFAULT_RANK',
  EMAIL_CONNECTIONS: 'EMAIL_CONNECTIONS',
  LOCATION: 'LOCATION',
  REPEATED_TEXT: 'REPEATED_TEXT',
  TOO_FAST: 'TOO_FAST',
  SHORT_TEXT: 'SHORT_TEXT',
}

export const SURVEY_STATE: Record<SurveyFormState, SurveyFormState> = {
  ALREADY_COMPLETED: 'ALREADY_COMPLETED',
  COMPLETED: 'COMPLETED',
  NOT_ACCEPTED: 'NOT_ACCEPTED',
  DISABLED: 'DISABLED',
  DISQUALIFIED: 'DISQUALIFIED',
  ERROR: 'ERROR',
  IN_PROGRESS: 'IN_PROGRESS',
  REDIRECTING: 'REDIRECTING',
  LOADING: 'LOADING',
  SCHEDULING: 'SCHEDULING',
}

export const DEFAULT_TIMEZONE = 'UTC'

export const ELEMENTS_PER_PAGE_AVAILABILITY_CALENDAR = 4

export const PAYMENT_METHOD_TYPE: Record<PaymentMethodType, PaymentMethodType> = {
  TREMENDOUS: 'TREMENDOUS',
}

export const INTERVIEW_PARTICIPANT_TYPE: Record<
  InterviewParticipantType,
  InterviewParticipantType
> = {
  HOST: 'HOST',
  PARTICIPANT: 'PARTICIPANT',
  OBSERVER: 'OBSERVER',
}

export const CALENDAR_PARTICIPANT_TYPE: Record<CalendarParticipantType, CalendarParticipantType> = {
  HOST: 'HOST',
  OBSERVER: 'OBSERVER',
}

export const APP_LAYOUT_TYPE: Record<AppLayoutType, AppLayoutType> = {
  SURVEY_FORM: 'SURVEY_FORM',
  INTERVIEW: 'INTERVIEW',
  ACCESS_DENIED: 'ACCESS_DENIED',
}
