import { SignOut } from '@phosphor-icons/react'
import classNames from 'classnames'
import { useRouter } from 'next/router'
import { PrimeIcons } from 'primereact/api'
import { Button } from 'primereact/button'

import { logoutLink } from '@hooks/use-user'

type Props = {
  shouldShowGoHome?: boolean
}

function getMessage(reason?: string) {
  switch (reason) {
    case 'DENIED_ORGANIZATION_ACCESS':
      return (
        <>
          You don’t have permissions to access the organization that you sign in for.
          <br /> Contact an administrator to get permissions or try to sign in with different
          account.
        </>
      )

    default:
      return (
        <>
          You don’t have permissions to access this page.
          <br /> Contact an administrator to get permissions or go back.
        </>
      )
  }
}

export function AccessDenied({ shouldShowGoHome }: Props) {
  const router = useRouter()
  const reason = router.query.reason?.toString()

  return (
    <div className='fixed left-0 top-0 z-[1001] flex h-screen w-screen items-center justify-center bg-white'>
      <div className='max-w-xl pb-32 text-center'>
        <i className={classNames(PrimeIcons.LOCK, 'mb-2 text-xl')} />
        <h2 className='h1'>Access denied</h2>
        <p className='mb-8 mt-2 text-sm text-gray-600'>{getMessage(reason)}</p>

        <div className='flex justify-center space-x-2'>
          {shouldShowGoHome ? (
            <Button
              className='p-button p-button-primary p-button-sm'
              label='Go home'
              onClick={() => router.push('/')}
            />
          ) : (
            <Button
              className='p-button p-button-primary p-button-sm'
              label='Go back'
              onClick={() => router.back()}
            />
          )}

          <Button
            className='p-button p-button-sm p-button-secondary p-button-outlined'
            icon={<SignOut className='mr-2' size={20} />}
            label='Sign out'
            onClick={() => {
              location.href = logoutLink
            }}
          />
        </div>
      </div>
    </div>
  )
}
