import { useMemo } from 'react'

import { Folder, FolderSearchParams } from '@typings/Folder'
import axios from 'axios'
import qs from 'query-string'
import useSWR, { KeyedMutator, SWRConfiguration } from 'swr'
import useSWRMutation from 'swr/mutation'

import { useUser } from '@hooks/use-user'
import { DashboardData } from '@views/dashboard/typings/Dashboard'

import type {
  AvailableSlots,
  Calendar,
  Interview,
  InterviewSearchParams,
  Timeslots,
} from '@typings/Interviews'
import type { SurveyLinkData, SurveyLinks } from '@typings/Link'
import type {
  Organisation,
  OrganisationBranding,
  OrganisationMetadata,
} from '@typings/Organisation'
import type { PaymentMethods } from '@typings/Payment'
import type { QuestionLibraryList } from '@typings/QuestionLibrary'
import type {
  SideLoadDataHeaderValues,
  SideLoadDataHeaders,
  SideLoadDataList,
} from '@typings/SideLoadData'
import type {
  SurveyClosureInfo,
  SurveyConfig,
  SurveyConfigOption,
  SurveyDefinition,
  SurveyQuota,
  SurveyQuotasStats,
  Surveys,
  SurveysSearchParams,
} from '@typings/Survey'

type SurveyQuotasReturnType = {
  surveyQuotas?: Array<SurveyQuota>
  surveyQuotasError: any
  mutateSurveyQuotas: KeyedMutator<Array<SurveyQuota>>
  isValidatingSurveyQuotas: boolean
  isLoadingSurveyQuotas: boolean
}

export function useSurveyQuotas(
  surveyId: string | null,
  options?: SWRConfiguration,
): SurveyQuotasReturnType {
  const { data, error, isLoading, isValidating, mutate } = useSWR<Array<SurveyQuota>>(
    surveyId && `/api/survey/${surveyId}/quota`,
    options,
  )

  return {
    surveyQuotas: data,
    surveyQuotasError: error,
    mutateSurveyQuotas: mutate,
    isValidatingSurveyQuotas: isValidating,
    isLoadingSurveyQuotas: isLoading,
  }
}

type SurveyQuotasStatsReturnType = {
  surveyQuotasStats?: SurveyQuotasStats
  surveyQuotasStatsError: any
  isSurveyQuotasStatsValidating: boolean
}

export function useSurveyQuotasStats(
  surveyId: string | null,
  options?: SWRConfiguration,
): SurveyQuotasStatsReturnType {
  const { data, error, isValidating } = useSWR<SurveyQuotasStats>(
    surveyId && `/api/project/${surveyId}/quota/stats`,
    options,
  )

  return {
    surveyQuotasStats: data,
    surveyQuotasStatsError: error,
    isSurveyQuotasStatsValidating: isValidating,
  }
}

type TimeslotsReturnType = {
  isValidatingTimeslots: boolean
  isLoadingTimeslots: boolean
  mutateTimeslots: KeyedMutator<Timeslots>
  timeslots: Timeslots['timeslotResponses']
  timeslotsError: any
}

export function useTimeslots(
  projectId: string | null,
  options?: SWRConfiguration,
): TimeslotsReturnType {
  const { data, error, isLoading, isValidating, mutate } = useSWR<Timeslots>(
    projectId && `/api/timeslots/project/${projectId}`,
    options,
  )

  return {
    timeslots: data?.timeslotResponses,
    mutateTimeslots: mutate,
    timeslotsError: error,
    isLoadingTimeslots: isLoading,
    isValidatingTimeslots: isValidating,
  }
}

type MutateTimeslotsReturnType = {
  isMutatingTimeslots: boolean
  timeslotsError: any
  updateTimeslots: ({ createdBy, timeslots }) => void
}

type CreateTimeslotArgs = Record<
  'arg',
  {
    timeslots: Array<{
      calendarId: string
      duration: number
      endTime: number
      organisationId: string
      projectId: string
      startTime: number
      timezone: string
      updatedBy: string
    }>
  }
>

async function updateTimeslots(url: string, { arg: { timeslots } }: CreateTimeslotArgs) {
  return axios.post(url, timeslots)
}

export function useMutateTimeslots(
  projectId: string | null,
  options?: SWRConfiguration,
): MutateTimeslotsReturnType {
  const { error, isMutating, trigger } = useSWRMutation(
    projectId && `/api/timeslots/project/${projectId}`,
    updateTimeslots,
    options,
  )

  return {
    isMutatingTimeslots: isMutating,
    timeslotsError: error,
    updateTimeslots: trigger,
  }
}

type SurveyDefinitionReturnType = {
  isSurveyDefinitionValidating: boolean
  isLoadingSurveyDefinition: boolean
  mutateSurveyDefinition: KeyedMutator<SurveyDefinition>
  surveyDefinition: SurveyDefinition
  surveyDefinitionError: any
}

export function useSurveyDefinition(
  surveyId: string | null,
  version?: number,
  options?: SWRConfiguration,
): SurveyDefinitionReturnType {
  const { data, error, isLoading, isValidating, mutate } = useSWR<SurveyDefinition>(
    surveyId && qs.stringifyUrl({ url: `/api/survey/${surveyId}/definition`, query: { version } }),
    options,
  )

  return {
    surveyDefinition: data,
    mutateSurveyDefinition: mutate,
    surveyDefinitionError: error,
    isSurveyDefinitionValidating: isValidating,
    isLoadingSurveyDefinition: isLoading,
  }
}

type SurveyDefinitionList = Array<{ name: string; version: number }>

type SurveyDefinitionListReturnType = {
  isSurveyDefinitionListValidating: boolean
  mutateSurveyDefinitionList: KeyedMutator<SurveyDefinitionList>
  surveyDefinitionList: SurveyDefinitionList
  surveyDefinitionListError: any
}

export function useSurveyDefinitionList(
  surveyId: string | null,
  options?: SWRConfiguration,
): SurveyDefinitionListReturnType {
  const { data, error, isValidating, mutate } = useSWR<SurveyDefinitionList>(
    surveyId && `/api/survey/${surveyId}/definition/list`,
    options,
  )

  return {
    surveyDefinitionList: data,
    mutateSurveyDefinitionList: mutate,
    surveyDefinitionListError: error,
    isSurveyDefinitionListValidating: isValidating,
  }
}

type SurveyConfigOptionsReturnType = {
  surveyConfigOptions: Array<SurveyConfigOption>
  surveyConfigOptionsError: any
}

export function useSurveyConfigOptions(options?: SWRConfiguration): SurveyConfigOptionsReturnType {
  const { data, error } = useSWR<Array<SurveyConfigOption>>(`/api/survey/config`, options)

  return { surveyConfigOptions: data, surveyConfigOptionsError: error }
}

type SurveyConfigsReturnType = {
  surveyConfigs: Array<SurveyConfig>
  surveyConfigsError: any
  isSuveyConfigsLoading: boolean
  mutateSurveyConfigs: KeyedMutator<Array<SurveyConfig>>
}

export function useSurveyConfigs(
  surveyId: string | null,
  options?: SWRConfiguration,
): SurveyConfigsReturnType {
  const { data, error, isLoading, mutate } = useSWR<Array<SurveyConfig>>(
    surveyId && `/api/survey/${surveyId}/config`,
    options,
  )

  return {
    surveyConfigs: data,
    surveyConfigsError: error,
    isSuveyConfigsLoading: isLoading,
    mutateSurveyConfigs: mutate,
  }
}

type MutateDefaultSurveyRewardReturnType = {
  isDefaultSurveyRewardMutating: boolean
  defaultSurveyRewardError: any
  updateDefaultSurveyReward: (defaultSurveyReward) => void
}

async function updateDefaultSurveyReward(url, { arg: { reward, surveyId, userName } }) {
  const defaultSurveyRewardData = {
    currency: reward?.currency,
    amount: reward?.amount,
    updatedBy: userName,
    createdInternally: true,
    name: `default${reward?.amount + reward?.currency}`,
    surveyId,
  }

  return axios.post(url, defaultSurveyRewardData)
}

export function useMutateDefaultSurveyReward(
  surveyId: string | null,
  options?: SWRConfiguration,
): MutateDefaultSurveyRewardReturnType {
  const { error, isMutating, trigger } = useSWRMutation(
    `/api/survey/${surveyId}/reward/default`,
    updateDefaultSurveyReward,
    options,
  )

  return {
    isDefaultSurveyRewardMutating: isMutating,
    defaultSurveyRewardError: error,
    updateDefaultSurveyReward: trigger,
  }
}

type SurveyClosureInfoReturnType = {
  isValidatingSurveyClosureInfo: boolean
  mutateSurveyClosureInfo: KeyedMutator<SurveyClosureInfo>
  surveyClosureInfo?: SurveyClosureInfo
  surveyClosureInfoError?: any
}

export function useSurveyClosureInfo(
  surveyId: string | null,
  options?: SWRConfiguration,
): SurveyClosureInfoReturnType {
  const {
    user: { organisationId },
  } = useUser()

  const { data, error, isValidating, mutate } = useSWR<SurveyClosureInfo>(
    organisationId && `/api/organisation/${organisationId}/project/${surveyId}/close/info`,
    options,
  )

  return {
    surveyClosureInfo: data,
    surveyClosureInfoError: error,
    mutateSurveyClosureInfo: mutate,
    isValidatingSurveyClosureInfo: isValidating,
  }
}

type OrganisationNameReturnType = {
  isLoadingOrganisationName: boolean
  organisationName?: string
  organisationError?: any
}

export function useOrganisationName(
  organisationId: string | null,
  options?: SWRConfiguration,
): OrganisationNameReturnType {
  const { data, error, isLoading } = useSWR<Organisation>(
    organisationId && `/api/organisation/${organisationId}`,
    options,
  )

  return {
    isLoadingOrganisationName: isLoading,
    organisationName: data?.name,
    organisationError: error,
  }
}

type OrganisationMetadataReturnType = {
  isLoadingOrganisationMetadata: boolean
  isValidatingOrganisationMetadata: boolean
  organisationMetadata?: OrganisationMetadata
  organisationMetadataError?: any
}

export function useOrganizationMetadata(
  options?: SWRConfiguration,
): OrganisationMetadataReturnType {
  const { user } = useUser()
  const organisationId = user['qrowdsy/organisationId']

  const { data, error, isLoading, isValidating } = useSWR<OrganisationMetadata>(
    organisationId && `/api/organisation/${organisationId}/metadata`,
    options,
  )

  return {
    isLoadingOrganisationMetadata: isLoading,
    isValidatingOrganisationMetadata: isValidating,
    organisationMetadata: data,
    organisationMetadataError: error,
  }
}

type OrganisationBrandingReturnType = {
  isLoadingOrganisationBranding: boolean
  organisationBranding?: OrganisationBranding
  organisationBrandingError?: any
}

export function useOrganisationBranding(
  options?: SWRConfiguration,
): OrganisationBrandingReturnType {
  const {
    user: { organisationId },
  } = useUser()

  const { data, error, isLoading } = useSWR<OrganisationBranding>(
    organisationId && `/api/organisation/${organisationId}/branding`,
    options,
  )

  return {
    isLoadingOrganisationBranding: isLoading,
    organisationBranding: data,
    organisationBrandingError: error,
  }
}

type MutateOrganisationReturnType = {
  isOrganisationMutating?: boolean
  updateOrganisation?: (organisationData) => void
}

async function updateOrganisation(url, { arg: organisationData }) {
  return axios.post(url, organisationData)
}

export function useMutateOrganisation(organisationId: string | null): MutateOrganisationReturnType {
  const { isMutating, trigger } = useSWRMutation(
    `/api/organisation/${organisationId}/branding`,
    updateOrganisation,
  )

  return {
    isOrganisationMutating: isMutating,
    updateOrganisation: trigger,
  }
}

type FoldersReturnType = {
  isValidatingFolders: boolean
  mutateFolders: KeyedMutator<Array<Folder>>
  folders?: Array<Folder>
  foldersError?: any
}

export function useFolders(
  searchParams?: FolderSearchParams,
  options?: SWRConfiguration,
): FoldersReturnType {
  const foldersApiURL = qs.stringifyUrl({
    url: '/api/folder/organisation/search',
    query: searchParams,
  })

  const { data, error, isValidating, mutate } = useSWR<Array<Folder>>(foldersApiURL, options)

  return {
    folders: data,
    foldersError: error,
    mutateFolders: mutate,
    isValidatingFolders: isValidating,
  }
}

type FolderReturnType = {
  isValidatingFolder: boolean
  isLoadingFolder: boolean
  mutateFolder: KeyedMutator<Folder>
  folder?: Folder
  folderError?: any
}

export function useFolder(folderId, options?: SWRConfiguration): FolderReturnType {
  const { data, error, isLoading, isValidating, mutate } = useSWR<Folder>(
    folderId && `/api/folder/${folderId}`,
    options,
  )

  return {
    folder: data,
    folderError: error,
    mutateFolder: mutate,
    isValidatingFolder: isValidating,
    isLoadingFolder: isLoading,
  }
}

type SurveysReturnType = {
  isValidatingSurveys: boolean
  isLoadingSurveys: boolean
  mutateSurveys: KeyedMutator<Surveys>
  surveys?: Surveys
  surveysError?: any
}

type UseSurveysArgs = {
  options?: SWRConfiguration
  searchParams?: SurveysSearchParams
} | null

export function useSurveys({ options, searchParams }: UseSurveysArgs = {}): SurveysReturnType {
  const surveysApiURL = useMemo(
    () =>
      qs.stringifyUrl({
        url: `/api/organisation/surveys`,
        query: searchParams,
      }),
    [searchParams],
  )

  const { data, error, isLoading, isValidating, mutate } = useSWR<Surveys>(surveysApiURL, options)

  return {
    surveys: data,
    isLoadingSurveys: isLoading,
    surveysError: error,
    mutateSurveys: mutate,
    isValidatingSurveys: isValidating,
  }
}

type InterviewReturnType = {
  isValidatingInterview: boolean
  mutateInterview: KeyedMutator<Array<Interview>>
  interview?: Array<Interview>
  interviewError?: any
}

export function useInterview(
  searchParams?: InterviewSearchParams,
  options?: SWRConfiguration,
): InterviewReturnType {
  const interviewApiURL = qs.stringifyUrl({
    url: '/api/interview/search',
    query: searchParams,
  })

  const { data, error, isValidating, mutate } = useSWR<Array<Interview>>(interviewApiURL, options)

  return {
    interview: data,
    interviewError: error,
    mutateInterview: mutate,
    isValidatingInterview: isValidating,
  }
}

type DashboardReturnType = {
  isValidatingDashboard: boolean
  mutateDashboard: KeyedMutator<DashboardData>
  dashboard?: DashboardData
  dashboardError?: any
}

export function useDashboard(
  { dashboardId, projectId }: { dashboardId: string; projectId: string },
  options?: SWRConfiguration,
): DashboardReturnType {
  const { data, error, isValidating, mutate } = useSWR(
    dashboardId && `/api/project/${projectId}/dashboard/${dashboardId}`,
    options,
  )

  return {
    dashboard: data,
    dashboardError: error,
    mutateDashboard: mutate,
    isValidatingDashboard: isValidating,
  }
}

type DashboardsReturnType = {
  isValidatingDashboards: boolean
  mutateDashboards: KeyedMutator<Array<DashboardData>>
  dashboards?: Array<DashboardData>
  dashboardsError?: any
}

export function useDashboards(projectId: string, options?: SWRConfiguration): DashboardsReturnType {
  const { data, error, isValidating, mutate } = useSWR(
    `/api/project/${projectId}/dashboard/list`,
    options,
  )

  return {
    dashboards: data,
    dashboardsError: error,
    mutateDashboards: mutate,
    isValidatingDashboards: isValidating,
  }
}

type PaymentMethodsReturnType = {
  isValidatingPaymentMethods: boolean
  mutatePaymentMethods: KeyedMutator<PaymentMethods>
  paymentMethods?: PaymentMethods
  paymentMethodsError?: any
}

export function usePaymentMethods(options?: SWRConfiguration): PaymentMethodsReturnType {
  const {
    user: { organisationId },
  } = useUser()

  const { data, error, isValidating, mutate } = useSWR(
    `/api/organisation/${organisationId}/payment-method/list`,
    options,
  )

  return {
    paymentMethods: data,
    paymentMethodsError: error,
    mutatePaymentMethods: mutate,
    isValidatingPaymentMethods: isValidating,
  }
}

type CalendarsReturnType = {
  isValidatingCalendars: boolean
  mutateCalendars: KeyedMutator<Array<Calendar>>
  calendars?: Array<Calendar>
  calendarsError?: any
}

export function useCalendars(projectId: string, options?: SWRConfiguration): CalendarsReturnType {
  const {
    user: { organisationId },
  } = useUser()

  const { data, error, isValidating, mutate } = useSWR(
    projectId && `/api/organisation/${organisationId}/project/${projectId}/calendar/list`,
    options,
  )

  return {
    calendars: data,
    calendarsError: error,
    mutateCalendars: mutate,
    isValidatingCalendars: isValidating,
  }
}

type CalendarReturnType = {
  isValidatingCalendar: boolean
  mutateCalendar: KeyedMutator<Calendar>
  calendar?: Calendar
  calendarError?: any
}

export function useCalendar(
  calendarId: string,
  projectId: string,
  options?: SWRConfiguration,
): CalendarReturnType {
  const {
    user: { organisationId },
  } = useUser()

  const { data, error, isValidating, mutate } = useSWR(
    calendarId &&
      projectId &&
      `/api/organisation/${organisationId}/project/${projectId}/calendar/${calendarId}`,
    options,
  )

  return {
    calendar: data,
    calendarError: error,
    mutateCalendar: mutate,
    isValidatingCalendar: isValidating,
  }
}

type AvailableSlotsReturnType = {
  isValidatingAvailableSlots: boolean
  mutateAvailableSlots: KeyedMutator<AvailableSlots>
  availableSlots?: AvailableSlots['availableSlots']
  availableSlotsError?: any
}

export function useAvailableSlots(
  { projectId, responseId }: { projectId: string; responseId: string },
  options?: SWRConfiguration,
): AvailableSlotsReturnType {
  const { data, error, isValidating, mutate } = useSWR<AvailableSlots>(
    projectId &&
      responseId &&
      `/api/interview/project/${projectId}/response/${responseId}/schedule/info`,
    options,
  )

  return {
    availableSlots: data?.availableSlots,
    availableSlotsError: error,
    mutateAvailableSlots: mutate,
    isValidatingAvailableSlots: isValidating,
  }
}

type SurveyLinksReturnType = {
  isValidatingSurveyLinks: boolean
  mutateSurveyLinks: KeyedMutator<SurveyLinks>
  surveyLinks?: SurveyLinks
  surveyLinksError?: any
}

export function useSurveyLinks(
  projectId: string,
  options?: SWRConfiguration,
): SurveyLinksReturnType {
  const { data, error, isValidating, mutate } = useSWR<SurveyLinks>(
    projectId && `/api/survey/${projectId}/link/list`,
    options,
  )

  return {
    surveyLinks: data,
    surveyLinksError: error,
    mutateSurveyLinks: mutate,
    isValidatingSurveyLinks: isValidating,
  }
}

type SurveyLinkReturnType = {
  isValidatingSurveyLink: boolean
  mutateSurveyLink: KeyedMutator<SurveyLinkData>
  surveyLink?: SurveyLinkData
  surveyLinkError?: any
}

export function useSurveyLink(linkId: string, options?: SWRConfiguration): SurveyLinkReturnType {
  const { data, error, isValidating, mutate } = useSWR<SurveyLinkData>(
    linkId && `/api/link/${linkId}`,
    options,
  )

  return {
    surveyLink: data,
    surveyLinkError: error,
    mutateSurveyLink: mutate,
    isValidatingSurveyLink: isValidating,
  }
}

type SideLoadDataListReturnType = {
  isValidatingSideLoadDataList: boolean
  mutateSideLoadDataList: KeyedMutator<SideLoadDataList>
  sideLoadDataList?: SideLoadDataList
  sideLoadDataListError?: any
}

export function useSideLoadDataList(
  projectId: string,
  options?: SWRConfiguration,
): SideLoadDataListReturnType {
  const { data, error, isValidating, mutate } = useSWR<SideLoadDataList>(
    projectId && `/api/project/${projectId}/side-load/csv/list`,
    options,
  )

  return {
    sideLoadDataList: data,
    sideLoadDataListError: error,
    mutateSideLoadDataList: mutate,
    isValidatingSideLoadDataList: isValidating,
  }
}

type SideLoadDataHeadersReturnType = {
  isValidatingSideLoadDataHeaders: boolean
  mutateSideLoadDataHeaders: KeyedMutator<SideLoadDataHeaders>
  sideLoadDataHeaders?: SideLoadDataHeaders['headers']
  sideLoadDataHeadersError?: any
}

export function useSideLoadDataHeaders(
  { csvId, projectId }: { projectId: string; csvId: string },
  options?: SWRConfiguration,
): SideLoadDataHeadersReturnType {
  const { data, error, isValidating, mutate } = useSWR<SideLoadDataHeaders>(
    projectId && `/api/project/${projectId}/side-load/csv/${csvId}/headers`,
    options,
  )

  return {
    sideLoadDataHeaders: data?.headers,
    sideLoadDataHeadersError: error,
    mutateSideLoadDataHeaders: mutate,
    isValidatingSideLoadDataHeaders: isValidating,
  }
}

type SideLoadDataValuesReturnType = {
  isValidatingSideLoadDataValues: boolean
  mutateSideLoadDataValues: KeyedMutator<SideLoadDataHeaderValues>
  sideLoadDataValues?: SideLoadDataHeaderValues['values']
  sideLoadDataValuesError?: any
}

export function useSideLoadDataValues(
  {
    csvId,
    projectId,
    sideLoadHeader,
  }: { sideLoadHeader: string; projectId: string; csvId: string },
  options?: SWRConfiguration,
): SideLoadDataValuesReturnType {
  const encodedCsvHeader = encodeURIComponent(sideLoadHeader)

  const { data, error, isValidating, mutate } = useSWR<SideLoadDataHeaderValues>(
    projectId &&
      `/api/project/${projectId}/side-load/csv/${csvId}/header/${encodedCsvHeader}/values`,
    options,
  )

  return {
    sideLoadDataValues: data?.values,
    sideLoadDataValuesError: error,
    mutateSideLoadDataValues: mutate,
    isValidatingSideLoadDataValues: isValidating,
  }
}

type QuestionLibraryListReturnType = {
  questionLibraryList?: QuestionLibraryList
  questionLibraryListError?: any
  isValidatingQuestionLibraryList: boolean
  mutateQuestionLibraryList: KeyedMutator<QuestionLibraryList>
  isLoadingQuestionLibraryList: boolean
}

export function useQuestionLibraryList(options?: SWRConfiguration): QuestionLibraryListReturnType {
  const {
    user: { organisationId },
  } = useUser()

  const { data, error, isLoading, isValidating, mutate } = useSWR<QuestionLibraryList>(
    organisationId && `/api/organisation/${organisationId}/question-library/list`,
    options,
  )

  return {
    questionLibraryList: data,
    questionLibraryListError: error,
    mutateQuestionLibraryList: mutate,
    isValidatingQuestionLibraryList: isValidating,
    isLoadingQuestionLibraryList: isLoading,
  }
}

type ReportingReturnType = {
  isValidatingReporting: boolean
  mutateReporting: KeyedMutator<DashboardData>
  reporting?: DashboardData
  reportingError?: any
}

export function useReporting(
  { projectId, reportingId }: { reportingId: string; projectId: string },
  options?: SWRConfiguration,
): ReportingReturnType {
  const { data, error, isValidating, mutate } = useSWR(
    reportingId && `/api/project/${projectId}/reporting/${reportingId}`,
    options,
  )

  return {
    reporting: data,
    reportingError: error,
    mutateReporting: mutate,
    isValidatingReporting: isValidating,
  }
}
