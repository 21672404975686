import { useEffect, useState } from 'react'

import { getPrimaryColorFromDOM } from '@components/Layout/SurveyFormLayout/helpers/get-primary-color-from-dom'
import { useOrganisationBranding } from '@hooks/use-survey-provider-service-api'
import { generateLightColor } from '@views/organisation/organisation-general/helpers/generate-light-color'

import type { Branding } from '@typings/Organisation'

export const PRIMARY_COLOR_FALLBACK = '#6159e8'

export function usePrimaryColor(): [string, boolean] {
  const [primaryColor, setPrimaryColor] = useState(PRIMARY_COLOR_FALLBACK)

  const { isLoadingOrganisationBranding, organisationBranding } = useOrganisationBranding()

  useBrandingColors(organisationBranding?.branding, (branding: Branding) => {
    setPrimaryColor(branding?.primaryColor)
  })

  return [primaryColor, isLoadingOrganisationBranding]
}

export function useBrandingColors(branding: Branding, callback: (branding: Branding) => void) {
  useEffect(() => {
    const primaryTextColorFallback = '#ffffff'
    const primaryColorFallback = getPrimaryColorFromDOM() || PRIMARY_COLOR_FALLBACK
    const primaryColorLightFallback = generateLightColor(primaryColorFallback)

    const primaryBranding = {
      ...branding,
      primaryColor: branding?.primaryColor || primaryColorFallback,
      primaryColorLight: branding?.primaryColorLight || primaryColorLightFallback,
      textColor: branding?.textColor || primaryTextColorFallback,
    }

    callback(primaryBranding)
  }, [branding, callback])
}
