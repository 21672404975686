import Script from 'next/script'

export function ZendeskWidget() {
  if (process.env.NODE_ENV === 'production') {
    return (
      <Script
        id='ze-snippet'
        src={`https://static.zdassets.com/ekr/snippet.js?key=${process.env.NEXT_PUBLIC_ZENDESK_API_KEY}`}
      />
    )
  }
}
