import { hslToColorString } from 'polished'

const PRIMARY_600_CSS_VARIABLE = '--color-primary-600'

export function getPrimaryColorFromDOM(): string | null {
  if (typeof document === 'undefined') {
    return null
  }

  const htmlElement = document.documentElement

  // read primary color from css variables
  const [hue, saturation, lightness] = getComputedStyle(htmlElement)
    .getPropertyValue(PRIMARY_600_CSS_VARIABLE)
    .trim()
    .split(' ')
    .map((color) => parseInt(color))

  // transform rgb to hex
  return hslToColorString({ hue, lightness: lightness / 100, saturation: saturation / 100 })
}
